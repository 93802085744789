<template>
	<b-card>
		<b-form>
			<quill-editor
				class="mb-3"
				v-model="text"
				:options="snowOption"
			/>
			<!-- submit buttons -->
			<div class="d-flex justify-content-end">
				<b-button
					type="submit"
					variant="primary"
					class="waves-effect waves-float waves-light"
				>
					Submit
				</b-button>
			</div>
		</b-form>
	</b-card>
</template>

<script>
import 'quill/dist/quill.snow.css'
import { BCard, BCardText, BForm, BButton } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
	quillEditor,
    BCard,
    BCardText,
	BForm,
	BButton
  },

  data() {
    return {
		// codeSnow,
		snowOption: {
			theme: 'snow',
		},
		text: '',
    
    }
  },

  computed: {
    
  },

  methods: {
    
  },

  async created () {
    
  }
}
</script>

<style>
	.ql-editor {
		height: 400px !important;
	}
</style>
